<template>
    <div>
        <CRow>
            <CCol sm="12" xl="12">
                <CCard>
                    <CCardHeader>
                        <strong>Data Source Detail</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CDataTable hover striped :items="items" :fields="fields" :noItemsView="{ noItems: 'No items to show.' }" >
                        </CDataTable>
                    </CCardBody>
                    <CCardFooter>
                        <CButton color="primary" @click="redirect_to(`/data/source/${$route.params.id}/update`)" class="mr-2">Update</CButton>
                        <CButton color="dark" @click="go_back">Back</CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
export default {
    data () {
        return {
            items: [],
        }
    },
    computed: {
        fields(){
            return [
                { key: 'key', label: 'Key'},
                { key: 'value', label: 'Value'}
            ]
        },
    },
    mounted(){
        this.get();
    },
    methods: {
        get() {
            this.axios.get('arbitrage/data_sources/'
                + '?id=' + this.$route.params.id)
                .then(res => {
                    res.data.map(val => {
                        switch(val.data_type){
                                case 1:
                                    val.data_type = 'API JSON - tgju.org';
                                    break;
                                case 2:
                                    val.data_type = 'tsetmc.com';
                                    break;
                                case 3:
                                    val.data_type = 'netdania.com';
                                    break;
                                case 4:
                                    val.data_type = 'HTML Processing';
                                    break;
                                case 5:
                                    val.data_type = 'Data Mining among Specific Expressions';
                                    break;
                                case 6:
                                    val.data_type = 'Data Mining from Telegram Channel';
                                    break;
                                case 7:
                                    val.data_type = 'Data Mining among Specific Expressions (Advanced)';
                                    break;
                            }
                        switch(val.is_active){
                            case true:
                                val.is_active = 'Active';
                                break;
                            case false:
                                val.is_active = 'Inactive';
                                break;
                        }
                        switch(val.log_data){
                            case true:
                                val.log_data = 'Active';
                                break;
                            case false:
                                val.log_data = 'Inactive';
                                break;
                        }
                        switch(val.current_state){
                            case true:
                                val.current_state = 'Active';
                                break;
                            case false:
                                val.current_state = 'Inactive';
                                break;
                        }
                        val.created_at =  this.$moment(val.created_at).format('YYYY-MM-DD HH:mm:ss');
                        if(val.updated_at != '0001-01-01T00:00:00Z'){
                            val.updated_at =  this.$moment(val.updated_at).format('YYYY-MM-DD HH:mm:ss');
                        }
                        if(val.last_check != '0001-01-01T00:00:00Z'){
                            val.last_check =  this.$moment(val.last_check).format('YYYY-MM-DD HH:mm:ss');
                        }
                        this.items.push(
                            {key: 'ID', value: val.id},
                            {key: 'Name', value: val.name},
                            {key: 'Source URL', value: val.source_url},
                            {key: 'Data Type', value: val.data_type},
                            {key: 'State', value: val.is_active},
                            {key: 'Current State', value: val.current_state},
                            {key: 'Data Logs', value: val.log_data},
                            {key: 'Check Each N Second', value: val.check_each_n_second},
                            {key: 'Description', value: val.detail},
                            {key: 'Last Check', value: val.last_check},
                            {key: 'Last Response Time', value: val.last_response_time},
                            {key: 'Ping', value: val.ping},
                            {key: 'Updated at', value: val.updated_at},
                            {key: 'Created at', value: val.created_at},
                        );
                    })
                })
        },
        redirect_to(route) {
            this.$router.push(route);
        },
        go_back(){
            this.$router.go(-1);
        }
    },
}
</script>
